import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";

type Container = {
  containerNo: string;
  containerType: string;
  status: string;
  date: string;
  LFD: React.ReactNode;
  price: React.ReactNode;
};

export type CommonKeys = "containerNo" | "status" | "date" | "LFD" | "price";

export default function useColumns() {
  const { t } = useTranslation();

  const columnHelper = createColumnHelper<Container>();

  const columns = [
    columnHelper.accessor("containerNo", {
      id: "containerNo",
      header: () => t("components:FreeTimeTable.컨테이너_NO."),
      cell: (info) => {
        const containerNo = info.row.original.containerNo;
        const containerType = info.row.original.containerType;

        return (
          <>
            {containerNo}
            <span className="container-type">{containerType}</span>
          </>
        );
      },
      meta: { hasFilter: true },
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => t("components:FreeTimeTable.상태"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () => t("components:FreeTimeTable.발생일"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("LFD", {
      id: "LFD",
      header: () => t("components:FreeTimeTable.LFD_프리타임_기한_"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("price", {
      id: "price",
      header: () => t("components:FreeTimeTable.발생요금"),
      cell: (info) => info.getValue(),
    }),
  ];

  return { columns };
}
